import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import ForgotPassword from './views/pages/login/ForgotPassword'
import ResetPassword from './views/pages/login/ResetPassword'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))

const App = () => {
  return (
    <Router>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/forgotPassword" name="Forgot Password" element={<ForgotPassword />} />
          <Route
            exact
            path="/ResetPassword/:token"
            name="Reset Password"
            element={<ResetPassword />}
          />
          {/* <Route exact path="/404" name="Page 404" element={<Page404 />} /> */}
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </Router>
  )
}

export default App
