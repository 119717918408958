// import { cilUser } from '@coreui/icons'
// import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  //   CInputGroupText,
  CRow,
} from '@coreui/react'
import { faLock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useState } from 'react'
// import { useNavigate } from 'react-router-dom'

const ForgotPassword = () => {
  const apiKey = process.env.REACT_APP_RECIPE_API

  // const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [successMessage, setSuccessMessage] = useState('')

  const myStyle = {
    backgroundImage:
      "url('https://images.pexels.com/photos/255379/pexels-photo-255379.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    await axios
      .post(`${apiKey}/forgotPass`, { useremail: email })
      .then((res) => {
        // console.log(res.data)
        if (res.data.response === 'User is not exist') {
          setSuccessMessage('')
          setMessage('User is not exist')
        }
        if (res.data.response === 'Mail send') {
          setMessage('')
          localStorage.setItem('emailMessage', 'Mail send Successfully')
          window.location.href = `/login`
          // navigate('/login')
        }
        setTimeout(() => {
          setSuccessMessage('')
          setMessage('')
        }, 3000)
      })
      .catch((error) => {
        // console.log(error.data)
        if (error?.data === undefined) {
          setSuccessMessage('')
          setMessage('Enter valid Email')
        }
        setTimeout(() => {
          setSuccessMessage('')
          setMessage('')
        }, 3000)
      })
  }

  return (
    <>
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center" style={myStyle}>
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={4}>
              <CCardGroup>
                <CCard className="p-4">
                  <CCardBody>
                    <CForm onSubmit={handleSubmit}>
                      {message ? (
                        <div className="alert alert-danger text-center" role="alert">
                          <strong>
                            <label>{message}</label>
                          </strong>
                        </div>
                      ) : null}
                      {successMessage && (
                        <div className="alert alert-success text-center" role="alert">
                          <strong>
                            <label>{successMessage}</label>
                          </strong>
                        </div>
                      )}
                      <div className="text-center mb-4 border-bottom">
                        <FontAwesomeIcon
                          className="mx-1"
                          icon={faLock}
                          style={{ height: '30px', color: 'rgb(31 30 30)' }}
                        />
                        <h2>Forgot Password</h2>
                      </div>
                      <div className="mb-3">
                        <CInputGroup>
                          <CFormInput
                            placeholder="Email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </CInputGroup>
                      </div>
                      <CRow>
                        <CCol xs={12}>
                          <CButton
                            type="submit"
                            color="primary"
                            className="w-100 bg-warning border-0"
                            onClick={handleSubmit}
                          >
                            <strong>Forgot Password</strong>
                          </CButton>
                        </CCol>
                      </CRow>
                    </CForm>
                  </CCardBody>
                </CCard>
              </CCardGroup>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </>
  )
}

export default ForgotPassword
