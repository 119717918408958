import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import { faKey, faTriangleExclamation, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const ResetPassword = () => {
  const apiKey = process.env.REACT_APP_RECIPE_API
  const navigate = useNavigate()

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [message, setMessage] = useState('')
  const [success, setSuccess] = useState('')
  const [expired, setExpired] = useState(true)
  const [showPassword, setShowPassword] = useState()

  const myStyle = {
    backgroundImage:
      "url('https://images.pexels.com/photos/255379/pexels-photo-255379.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')",
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  }

  const { token } = useParams()

  const handleReset = async () => {
    await axios
      .post(`${apiKey}/resetPass/${token}`, { password: password })
      .then((response) => {
        console.log(response.data.response)
        if (response.data.response === 'Password reset') {
          setSuccess('Password Reset Successfully')
          setMessage('')
          navigate('/login')
        }
        if (response.data.response === 'Valid Link') {
          setExpired(true)
        }
      })
      .catch((err) => {
        console.log(err.response.data.response)
        if (err.response.data.response === 'Link expired') {
          setExpired(false)
        } else if (err.response.data.response === 'Old Password is not taken as new Password') {
          setMessage('Old Password is not taken as new Password')
          setSuccess('')
        } else if (err.response.data.response) {
          setMessage(
            'Required Length of password is 8 to 16 characters, includes at least 1 UpperCase ,1 letter, 1 number and 1 special character',
          )
          setSuccess('')
        }
      })
  }
  useEffect(() => {
    handleReset()
  }, [])

  const handleSubmit = async (event) => {
    event.preventDefault()

    if (password?.length === 0) {
      setMessage('Enter New Password!')
      setSuccess('')
    } else if (confirmPassword?.length === 0) {
      setMessage('Enter Confirm Password!')
      setSuccess('')
    } else if (password !== confirmPassword) {
      setMessage('Confirm Password does not match')
      setSuccess('')
    } else if (password === confirmPassword) {
      handleReset()
    }
  }

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleReturn = () => {
    navigate('/login')
  }

  return expired === true ? (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center" style={myStyle}>
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={4}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    {message ? (
                      <div className="alert alert-danger text-center" role="alert">
                        <strong>
                          <label>{message}</label>
                        </strong>
                      </div>
                    ) : null}
                    {success && (
                      <div className="alert alert-success text-center" role="alert">
                        <strong>
                          <label>{success}</label>
                        </strong>
                      </div>
                    )}
                    <div className="text-center mb-4 border-bottom">
                      <FontAwesomeIcon
                        className="mx-1"
                        icon={faKey}
                        style={{ height: '30px', color: 'rgb(31 30 30)' }}
                      />
                      <h2>Reset Password</h2>
                    </div>

                    <div className="mb-3">
                      <CInputGroup>
                        <CFormInput
                          type={showPassword ? 'text' : 'password'}
                          value={password}
                          id="newpassword"
                          placeholder="New Password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        <CInputGroupText onClick={toggleShowPassword}>
                          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                        </CInputGroupText>
                      </CInputGroup>
                    </div>
                    <div className="mb-3">
                      <CInputGroup>
                        <CFormInput
                          type={showPassword ? 'text' : 'password'}
                          value={confirmPassword}
                          id="conformpassword"
                          placeholder="Confirm Password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </CInputGroup>
                    </div>
                    <CRow>
                      <CCol xs={12}>
                        <CButton color="primary" type="submit" className="w-100">
                          Reset Password
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  ) : (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center" style={myStyle}>
      <CContainer className="mt-5">
        <CRow className="justify-content-center">
          <CCol md={4}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <CRow>
                      <CCol>
                        <div className="text-center">
                          <FontAwesomeIcon
                            icon={faTriangleExclamation}
                            style={{ height: '40px', color: 'rgb(31 30 30)' }}
                          />
                          <h2>Oops! Link Expired</h2>
                          <p className="lead">Your link has expired. Please log in again.</p>
                        </div>
                        <CRow className="mt-4">
                          <CCol xs={12}>
                            <CButton
                              color="primary"
                              type="submit"
                              className="w-100"
                              onClick={handleReturn}
                            >
                              Log in
                            </CButton>
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default ResetPassword
